@import "shared/snippets/variables.scss";

.input-submit-button {
	@include button-green--no-hover;

	&--small {
		padding: 0;
	}
	&--middle {
		padding: 7px 0;
	}
	&:hover {
		color: $white;
	}
	&--orange {
		background-color: $orange;
		border-color: $orange;
	}
	&--gray {
		background-color: $gray;
		border-color: $gray;
		color: $darkgray;
		&:hover {
			color: $darkgray;
		}
	}

	&:disabled, &.disabled {
		cursor: not-allowed;
		opacity: 0.7;

		&:hover {
			box-shadow: none !important;
		}
	}
}

.input-submit-button--max-width {
	max-width: 200px;
}
