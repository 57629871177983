@import "shared/snippets/variables.scss";

.input-field {
	position: relative;
	padding-bottom: 15px;

	.input-text,
	.input-password,
	.input-datepicker,
	.input-number,
	.input-email,
	.input-tel,
	.input-textarea,
	button.dropdown-toggle {
		@include input-text;

		&.error {
			border-color: $errorRed;
		}

		&.untouched {
			box-shadow: none;
		}
	}

	.input-radio {
		&.error {
			border-color: $errorRed;
		}
	}
}

input:disabled {
	cursor: not-allowed;
}

.input-checkbox-list,
.input-radio-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.input-checkbox-list__label,
.input-radio-list__label {
	font-family: $font;
	color: $darkgray;
}

.input-checkbox-list__input,
.input-radio-list__input {
	margin-right: 5px;
	margin-left: 5px;
}

.input--icon {
	padding-right: 30px;
}

.input--icon-front {
	padding-left: 30px;
}

.input-label {
	@include input-label;
}

.input-label__icon {
	margin: 7px 7px 0 7px;
	color: $gray-medium;

	.fas {
		font-size: 14px;
	}
}

.input-message {
	display: block;
	visibility: hidden;
	max-height: 0;
	opacity: 0;
	padding: 0;
	margin: 0;
	transition: visibility 0.45s ease-out, opacity 0.45s ease-out, max-height 0.3s ease-out 0.3s;

	&.visible {
		visibility: visible;
		height: auto;
		max-height: 200px;
		opacity: 1;
		padding: 10px 10px 0 5px;
		transition: visibility 0.45s ease-in 0.2s, opacity 0.45s ease-in 0.2s, max-height 0.3s ease-in;
	}

	&--change,
	&--duplicate,
	&--error,
	&--required {
		@include warning-label;
	}

	&--notice {
		@include warning-label;
		padding: 10px 10px 0 5px;
	}

	&--info {
		@include info-label;
		color: $primaryColor;
	}

	&--checklabel {
		@include info-label;
	}

	&--duplicate.input-message {
		padding-top: 0;
	}

	ul {
		margin-bottom: 0;
	}
}

.input-message--recaptcha.visible {
	text-align: center;
	padding: 5px 0 0 0;
}

.input-message--required.visible+.input-message--error {
	display: none;
}

.input-checkbox--password {
	margin-top: 10px;
}

.input-checkpassword {
	margin-right: 5px;
}

.password-criteria-approved,
.password-set-approved {
	color: $primaryColor;
}

.input__icon {
	padding: 5px 0 0 0;
	position: absolute;
	top: 6px;
	right: 10px;
	display: block;

	&.input__icon--password {
		cursor: pointer;
		padding-top: 8px;

		&:nth-child(3) {
			right: 35px;
		}
	}

	.fas {
		color: $darkgray;
		font-size: 14px;
	}
}

.input__icon-wrapper {
	position: relative;
}

.input__icon--front {
	@extend .input__icon;
	right: unset;
	left: 10px;
}

input[type="radio"].input-field__radio--circle {
	background-color: $white;
	border-radius: 50%;
	box-shadow: inset 0 1px 1px hsla(0, 0%, 100%, 0.8), 0 0 0 1px hsla(0, 0%, 0%, 0.3), 0 2px 3px hsla(0, 0%, 0%, 0.2),
		0 4px 3px hsla(0, 0%, 0%, 0.1);
	cursor: pointer;
	display: inline-block;
	height: 15px;
	margin-right: 5px;
	position: relative;
	width: 15px;
	-webkit-appearance: none;

	&:after {
		background-color: transparent;
		border-radius: 50%;
		content: "";
		display: block;
		height: 15px;
		left: 0;
		position: relative;
		top: 0;
		width: 15px;
		border: 1px solid $darkgray;
		transition: .15s ease-in-out;
	}

	&:checked:after {
		background-color: $primaryColor;
	}

	&:focus {
		outline: none;
	}
}