.loader {
  background-color: #f4f4fa;

  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;

  &__message {
    position: absolute;
    top: 35%;
    left: 50%;
    width: 80%;
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-align: center;
    transform: translateX(-50%);
  }

  &__spinner {
    width: 40px;
    height: 40px;

    position: relative;
    top: calc(50% - 19px);
    left: calc(50% - 19px);
  }

  &--has-opacity {
    opacity: 0.8;
  }
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: green;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}