/* You can add global styles to this file, and also import other style files */
@import "shared/snippets/variables.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "shared/input/input.component.scss";
@import "shared/input/button.component.scss";
@import "shared/loader.scss";
/* Master Styles */

a {
	cursor: pointer;
	color: $primaryColor;

	&:hover {
		color: $primaryColor;
	}

	text-decoration: none;
	background-color: transparent;
}

.fa-ul-check {
	padding-left: 0;
	margin-left: 30px;

	li {
		padding-left: 5px;
		line-height: 2em;
	}

	i {
		color: $primaryColor;
	}
}

.ng-select,
.ng-select.ng-select-single,
.ng-select.ng-select-multiple {
	color: $darkgray;
	font-family: $default-font;
	font-weight: $weight-300;

	.ng-select-container,
	.ng-value-container {
		height: 50px;
	}

	.ng-select-container .ng-value-container {

		.ng-input,
		.ng-placeholder {
			top: 12px !important;
		}
	}
}

.template-content {
	background-color: $darker-grey-color;
}

.fa-spin {
	display: inline-block;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	margin: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: $darkgray;
	text-align: left;
	background-color: #fff;
}

html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
}

dl,
ol,
ul {
	margin-top: 0;
	margin-bottom: 1rem;
}

img {
	vertical-align: middle;
	border-style: none;
}

:focus {
	outline: unset !important;
}

hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
}

.h1,
h1 {
	font-size: 2.5rem;
}

.h2,
h2 {
	font-size: 2rem;
}

.h3,
h3 {
	font-size: 1.75rem;
}

.h4,
h4 {
	font-size: 1.5rem;
}

.h5,
h5 {
	font-size: 1.25rem;
}

.h6,
h6 {
	font-size: 1rem;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

label {
	display: inline-block;
	margin-bottom: 0.5rem;
}

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
}

button,
input {
	overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

.close {
	float: right;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.5;
	cursor: pointer;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
	opacity: 0.75;
}

.close:hover {
	color: #000;
	text-decoration: none;
}

@media (min-width: 576px) {
	.container {
		max-width: 540px;
	}
}

@media (min-width: 576px) {

	.container,
	.container-sm {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container {
		max-width: 720px;
	}
}

@media (min-width: 768px) {

	.container,
	.container-md,
	.container-sm {
		max-width: 720px;
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 960px;
	}
}

@media (min-width: 992px) {

	.container,
	.container-lg,
	.container-md,
	.container-sm {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1140px;
	}
}

@media (min-width: 1200px) {

	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl {
		max-width: 1140px;
	}
}

.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

*,
::after,
::before {
	box-sizing: border-box;
}

#fc_frame,
#fc_frame.fc-widget-normal {
	bottom: 10px;
}

input[type="radio"].input-field__radio--circle {
	background-color: $white;
	border-radius: 50%;
	box-shadow: inset 0 1px 1px hsla(0, 0%, 100%, 0.8), 0 0 0 1px hsla(0, 0%, 0%, 0.3), 0 2px 3px hsla(0, 0%, 0%, 0.2),
		0 4px 3px hsla(0, 0%, 0%, 0.1);
	cursor: pointer;
	display: inline-block;
	height: 15px;
	margin-right: 5px;
	position: relative;
	width: 15px;
	-webkit-appearance: none;

	&:after {
		background-color: transparent;
		border-radius: 50%;
		content: "";
		display: block;
		height: 15px;
		left: 0;
		position: relative;
		top: 0;
		width: 15px;
		border: 1px solid $darkgray;
		transition: 0.15s ease-in-out;
	}

	&:checked:after {
		background-color: $primaryColor;
	}

	&:focus {
		outline: none;
	}
}

.msg-wrapper {
	position: fixed;
	display: block;
	font-family: $font;
	bottom: 15px;
	width: 440px;
	overflow-y: auto;
	z-index: 9999999999;
	height: 0;
	opacity: 0;
	padding: 0;

	@media (min-width: $small) {
		right: 7px;
	}

	@media (max-width: $small - 1px) {
		width: 100%;
		text-align: center;
	}

	&.visible {
		height: auto;
		opacity: 1;
		padding: 10px;
	}
}

section img {
	max-width: 100%;
	height: auto;
}

.default-tooltip {
	max-width: 280px !important;
	background-color: $white;
	box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.4);
	border-radius: 5px;
	color: $darkgray !important;
	line-height: 1.6;
	font-size: 14px;
}

.carousel {
	z-index: 4 !important;
}

.mat-mdc-raised-button {
	min-width: 120px;
	font-weight: 500;
	text-transform: uppercase;
	border-radius: 6px;
	text-decoration: none;
	font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
}

.mat-mdc-raised-button.mat-mdc-primary {
	background-color: $primaryColor !important;

	&:hover {
		background-color: darken($primaryColor, 10%) !important;
	}
}

.mat-mdc-dialog-container {
	position: relative;
	// iOS fixes
	padding: 0 !important;
}

// copied from old dialog
.default-dialog {
	width: 457px;
	background: white;
}

.packages-dialog {
	background: white;
	border-radius: 20px;
	width: auto !important;

	.mat-mdc-dialog-container .mdc-dialog__surface {
		border-radius: 20px;
		background-image: url('/assets/images/packages-background.png');
		overflow: visible;
	}

	app-dialog-core {
		overflow-y: scroll;
	}

	.fa-shield-check {
		color: #f8dc98;

		&:hover {
			cursor: pointer;
			color: darken(#f8dc98, 10%);
		}

		@include max-small() {
			display: flex;
			justify-content: center;
		}
	}
}

.appointment-dialog {
	width: 390px;
	box-sizing: border-box;
	background-color: $gray;
	border-radius: $border-radius-base;
	border: 1px solid rgba($darkgray, 0.2);

	[mat-mdc-dialog-title] {
		font-family: $font !important;
		font-weight: $weight-bold !important;
		font-size: 18px !important;
		line-height: 1.3em !important;
		padding: 24px 24px 15px 24px !important;
	}

	.mat-mdc-dialog-container .mdc-dialog__surface {
		background: transparent;
		overflow: visible;
	}

	.mat-dialog-container {
		background: transparent;
	}

	[mat-mdc-dialog-content] {
		max-height: 560px !important;

		[mat-mdc-dialog-container] {
			background: transparent;
		}
	}
}

.appointment-edit-dialog {
	@media screen and (max-width: 430px) {
		max-width: unset !important;
		width: 100%;
	}
}

.calendar-available-slot {
	.cal-event {
		border: 0px;
		border-color: blue !important;
	}

	opacity: 0.7;
}

.overview-wrapper {
	.mat-form-field.table-filter__select {
		.mat-form-field-wrapper {
			padding: 0;
			width: 100%;
		}

		.mat-form-field-underline {
			display: none;
		}

		.mat-form-field-infix {
			border: none;
		}
	}
}

//Needed due to the fact that we have a  messagebird chat widget that is not part of the angular app
// and we want to have our overlay container on top of it
.cdk-overlay-container {
	z-index: 10001;
}

.ng-dropdown-panel {
	z-index: 10005 !important;
}

.full-layout-dialog {
	max-width: 100%;
}

.mat-dialog-container {
	position: relative;
}

/* Branding on the banner */
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
	display: none;
}

/* Branding on the Privacy trigger */
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
	display: none;
}

.hidden-dialog {
	opacity: 0;
}

.cdk-overlay-container {

	.cdk-overlay-backdrop,
	.cdk-global-overlay-wrapper {
		display: none;
	}

	// Show the last two .cdk-overlay-backdrop elements
	.cdk-overlay-backdrop:nth-last-child(-n+2) {
		display: flex;
	}

	// Show the last two .cdk-global-overlay-wrapper elements
	.cdk-global-overlay-wrapper:nth-last-child(-n+2) {
		display: flex;
	}
}