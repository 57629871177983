/* poppins-300 - latin_latin-ext_devanagari */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	src: local("Poppins Light"), local("Poppins-Light"),
		url("/assets/font/poppins/poppins-v9-latin_latin-ext_devanagari-300.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("/assets/font/poppins/poppins-v9-latin_latin-ext_devanagari-300.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}
/* poppins-regular - latin_latin-ext_devanagari */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	src: local("Poppins Regular"), local("Poppins-Regular"),
		url("/assets/font/poppins/poppins-v9-latin_latin-ext_devanagari-regular.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("/assets/font/poppins/poppins-v9-latin_latin-ext_devanagari-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}
/* poppins-500italic - latin_latin-ext_devanagari */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 500;
	src: local("Poppins Medium Italic"), local("Poppins-MediumItalic"),
		url("/assets/font/poppins/poppins-v9-latin_latin-ext_devanagari-500italic.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("/assets/font/poppins/poppins-v9-latin_latin-ext_devanagari-500italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}
/* poppins-500 - latin_latin-ext_devanagari */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	src: local("Poppins Medium"), local("Poppins-Medium"),
		url("/assets/font/poppins/poppins-v9-latin_latin-ext_devanagari-500.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("/assets/font/poppins/poppins-v9-latin_latin-ext_devanagari-500.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}
/* poppins-600 - latin_latin-ext_devanagari */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	src: local("Poppins SemiBold"), local("Poppins-SemiBold"),
		url("/assets/font/poppins/poppins-v9-latin_latin-ext_devanagari-600.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("/assets/font/poppins/poppins-v9-latin_latin-ext_devanagari-600.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}
/* poppins-700italic - latin_latin-ext_devanagari */
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 700;
	src: local("Poppins Bold Italic"), local("Poppins-BoldItalic"),
		url("/assets/font/poppins/poppins-v9-latin_latin-ext_devanagari-700italic.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("/assets/font/poppins/poppins-v9-latin_latin-ext_devanagari-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}
/* poppins-700 - latin_latin-ext_devanagari */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	src: local("Poppins Bold"), local("Poppins-Bold"),
		url("/assets/font/poppins/poppins-v9-latin_latin-ext_devanagari-700.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("/assets/font/poppins/poppins-v9-latin_latin-ext_devanagari-700.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}
