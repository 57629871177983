@import "./_defaults.scss";

// buttons
@mixin button-green {
	background-color: $primaryColor;
	border: solid 2px $primaryColor;
	color: $white;
	transition-delay: 0s;
	transition-duration: 0.2s;
	transition-property: all;
	font-weight: $weight-bold;
	text-transform: uppercase;
	border-radius: 6px;
	text-decoration: none;
	font-family: $font;
	cursor: pointer;
	font-size: 16px;
	box-sizing: border-box;
	padding: 14px 0px;
	line-height: 1em;
	width: 100%;
	display: block;
	text-align: center;

	&:hover {
		background: transparent;
		color: $primaryColor;
		text-decoration: none;
	}
}

@mixin button-bordered($color: $primaryColor, $bg: transparent, $transition: 0.2s) {
	background-color: $bg;
	border: solid 2px $color;
	color: $color;
	transition-delay: 0s;
	transition-duration: $transition;
	transition-property: all;
	font-weight: $weight-bold;
	text-transform: uppercase;
	border-radius: 6px;
	text-decoration: none;
	font-family: $font;
	cursor: pointer;
	font-size: 16px;
	box-sizing: border-box;
	padding: 14px 0px;
	line-height: 1em;
	width: 100%;
	display: block;
	text-align: center;

	&:hover {
		background: $color;
		color: $white;
		text-decoration: none;
	}
}

@mixin button-stable($color: $primaryColor, $bg: transparent, $transition: 0.2s) {
	background-color: $bg;
	border: solid 2px $bg;
	color: $color;
	transition-delay: 0s;
	transition-duration: $transition;
	transition-property: all;
	font-weight: $weight-bold;
	text-transform: uppercase;
	border-radius: 6px;
	text-decoration: none;
	font-family: $font;
	cursor: pointer;
	font-size: 16px;
	box-sizing: border-box;
	padding: 14px 0px;
	line-height: 1em;
	display: block;
	text-align: center;
	padding-right: 6px;
	padding-left: 6px;
}

@mixin button-no-hover($backgroundColor: $primaryColor, $color: $white) {
	background-color: $backgroundColor;
	border: solid 2px $backgroundColor;
	color: $color;
	transition-delay: 0s;
	transition-duration: 0.2s;
	transition-property: all;
	font-weight: $weight-bold;
	text-transform: uppercase;
	border-radius: 6px;
	text-decoration: none;
	font-family: $font;
	cursor: pointer;
	font-size: 16px;
	box-sizing: border-box;
	padding: 14px 0px;
	line-height: 1em;
	width: 100%;
	display: block;
	text-align: center;

	&:hover {
		text-decoration: none;
		box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17) !important;
	}
}

@mixin button-green--no-hover {
	@include button-no-hover($primaryColor);
}

@mixin button-squared {
	border-radius: 6px;
	padding: 15px 20px;

	&:hover {
		text-decoration: none;
	}
}

@mixin button-rounded {
	border-radius: 22px;
	padding: 6px 12px;

	&:hover {
		text-decoration: none;
	}
}

// inputs

@mixin input-text {
	padding: 10px 15px;
	transition: 0.7s;
	transition-delay: 0s;
	transition-duration: 0.2s;
	transition-property: all;
	border-radius: 6px;
	color: $dark-blue;
	font-size: 16px;
	display: block;
	height: auto;
	border: solid 1px $gray-medium;
	font-family: $font;
	width: 100%;
	line-height: inherit;

	&:focus {
		outline: 0;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
		transition: 0.7s;
	}
}

@mixin input-label {
	color: $darkgray;
	font-weight: $weight-bold;
	font-size: 16px;
	font-family: $font;
	line-height: 1em;
	margin-bottom: 12px;
	padding-left: 5px;
}

@mixin error-label {
	color: $errorRed;
	font-weight: $weight-bold;
	font-size: 16px;
	font-family: $font;
	line-height: 1.3em;
	margin: 0;
	padding-left: 5px;
}

@mixin warning-label {
	color: $orange;
	font-weight: $weight-bold;
	font-size: 16px;
	font-family: $font;
	line-height: 1.3em;
	margin: 0;
	padding-left: 5px;
}

@mixin info-label {
	color: $darkgray;
	font-weight: $weight-bold;
	font-size: 16px;
	font-family: $font;
	line-height: 1.3em;
	margin: 0;
	padding-left: 5px;
}

// flex
@mixin flex-center() {
	display: flex;
	align-items: center;
	justify-content: center;
}

// media queries
// min-width
@mixin minimum() {
	@media screen and (min-width: $minimum) {
		@content;
	}
}

@mixin max-minimum() {
	@media screen and (max-width: $minimum) {
		@content;
	}
}

@mixin xsmall() {
	@media screen and (min-width: $xsmall) {
		@content;
	}
}

@mixin max-xsmall() {
	@media screen and (max-width: $xsmall) {
		@content;
	}
}

@mixin small() {
	@media screen and (min-width: $small) {
		@content;
	}
}

@mixin max-small() {
	@media screen and (max-width: $small) {
		@content;
	}
}

@mixin middle() {
	@media screen and (min-width: $middle) {
		@content;
	}
}

@mixin max-middle() {
	@media screen and (max-width: $middle) {
		@content;
	}
}

@mixin large() {
	@media screen and (min-width: $large) {
		@content;
	}
}

@mixin max-large() {
	@media screen and (max-width: $large) {
		@content;
	}
}

@mixin xlarge() {
	@media screen and (min-width: $xlarge) {
		@content;
	}
}

@mixin max-xlarge() {
	@media screen and (max-width: $xlarge) {
		@content;
	}
}

@mixin xxlarge() {
	@media screen and (min-width: $xxlarge) {
		@content;
	}
}

@mixin max-xxlarge() {
	@media screen and (max-width: $xxlarge) {
		@content;
	}
}

@mixin minQuery($minWidth) {
	@media screen and (min-width: $minWidth) {
		@content;
	}
}

@mixin maxQuery($maxWidth) {
	@media screen and (max-width: $maxWidth) {
		@content;
	}
}

@mixin mediaQueryBand($minWidth, $maxWidth) {
	@media screen and (min-width: $minWidth) and (max-width: $maxWidth) {
		@content;
	}
}

// check if hover's available
@mixin hover() {
	@media (hover: hover) {
		&:hover {
			@content;
		}
	}
}

// line hover effect
@mixin line-hover($bottom: -2px, $bg: currentColor) {
	position: relative;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		color: $bg;
	}

	&:after {
		content: "";
		width: 0;
		height: 1px;
		position: absolute;
		bottom: $bottom;
		left: 0;
		background-color: $bg;
		transition: width 0.2s ease-in-out;
	}

	@include hover {
		&:after {
			width: 100%;
		}
	}
}

// @include line-hover();
// @include line-hover(5px, red);
// @include line-hover($bg: silver);

@mixin button-hover($color, $duration: 0.2s) {
	@include xlarge {
		cursor: pointer;
		transition: background-color $duration ease-in-out;

		@include hover {
			background-color: darken($color, 10%);
		}

		&:active {
			background-color: lighten($color, 2%);
		}
	}
}

// @include button-hover(red);
// @include button-hover(red, .3s);

@mixin button-hover-bordered($color, $bg) {
	@include xlarge {
		cursor: pointer;
		transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;

		@include hover {
			background-color: $bg;
			color: $color;
		}
	}
}

// @include button-hover-bordered($color: #fff, $bg: #f00);

@mixin container {
	width: 90%;
	margin: 0 auto;
	padding: 20px 15px;

	@include xlarge() {
		max-width: 1170px;
		margin-left: auto;
		margin-right: auto;
		width: 90%;
		padding: 20px 0;
	}
}

@mixin container-new {
	width: 90%;
	margin: 0 auto;
	padding: 20px 15px;

	@include xlarge() {
		max-width: 1170px;
		margin-left: auto;
		margin-right: auto;
		width: 90%;
		padding: 20px 0;
	}
}

.is-container {
	@include container();
}

.is-container-admin {
	@include container();

	@include xlarge() {
		max-width: 900px;
	}
}

@mixin errorlabel {
	color: $red-alert;
	font-weight: $weight-bold;
}

.errorlabel {
	@include errorlabel();
}

@mixin button-base-styles($width: 100%, $color: $white, $backgroundColor: $primaryColor) {
	appearance: none;
	box-sizing: border-box;

	display: block;
	width: $width;
	margin: 0;
	padding: 14px 20px;

	background-color: $backgroundColor;
	border: solid 2px $backgroundColor;
	border-radius: $border-radius-base;

	font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
	font-size: $font-size-base;
	font-weight: $weight-bold;

	text-transform: uppercase;
	text-align: center;

	&,
	&:hover {
		text-decoration: none;
		color: $color;
	}
}

@mixin button-small-styles($width: 100%, $color: $white, $backgroundColor: $primaryColor) {
	@include button-base-styles($width, $color, $backgroundColor);
	height: 30px;
	padding: 4px 8px;
	font-size: 13px;
}

@mixin tag-styles($backgroundColor: $primaryColor, $color: $white) {
	appearance: none;
	box-sizing: border-box;

	display: block;
	margin: 0;
	padding: 1px 4px;

	background-color: $backgroundColor;
	border: solid 1px $backgroundColor;
	border-radius: $border-radius-base;

	font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
	font-size: 12px;
	font-weight: $weight-bold;

	text-align: center;
	color: $color;

	&,
	&:hover {
		text-decoration: none;
	}
}

@mixin placeholder-animation-left($class, $transition: 0.3s, $text-indent: -210px) {
	.#{$class}::-webkit-input-placeholder {
		will-change: text-indent;
		text-indent: 0;
		transition: text-indent $transition;
	}

	.#{$class}:focus::-webkit-input-placeholder {
		text-indent: $text-indent;
	}

	.#{$class}::-moz-placeholder {
		will-change: text-indent;
		text-indent: 0;
		transition: text-indent $transition;
	}

	.#{$class}:focus::-moz-placeholder {
		text-indent: $text-indent;
	}

	.#{$class}:-moz-placeholder {
		will-change: text-indent;
		text-indent: 0;
		transition: text-indent $transition;
	}

	.#{$class}:focus:-moz-placeholder {
		text-indent: $text-indent;
	}

	.#{$class}:-ms-input-placeholder {
		will-change: text-indent;
		text-indent: 0;
		transition: text-indent $transition;
	}

	.#{$class}:focus:-ms-input-placeholder {
		text-indent: $text-indent;
	}
}

@mixin loading() {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $white;
	@include flex-center;

	display: none;

	i {
		font-size: 25px;
		color: $darkgray;

		@include middle {
			font-size: 35px;
		}

		@include xlarge {
			font-size: 40px;
		}
	}

	&.is-active {
		display: flex;
	}
}

// @include placeholder-animation-left(input-left);

@mixin rounded-border($border-colour) {
	border-radius: $border-radius-base;
	border: solid 2px $border-colour;
}

@mixin hover-box($color) {
	cursor: pointer;
	transition-duration: 0.2s;
	border-radius: 3px;

	&:hover {
		box-shadow: 1px 1px 6px rgba(50, 50, 50, 0.2);
		color: $color;
	}
}

@mixin textStyle {
	color: $darkgray;
	font-size: 16px;
}

@mixin grayBackgroundBorder {
	background-color: $gray-100;
	border: solid 1px $gray-200;
	border-radius: $border-radius-base * 2;
}