// colour variables
// $primaryColor: #5cc75f;
$primaryColor: rgb(92, 199, 95);
// $gray: #f1f1f1;
$gray: rgb(241, 241, 241);
// $lightgray: #fafbfc;
$lightgray: rgb(250, 251, 252);
// $darkgray: #486066;
$darkgray: rgb(72, 96, 102);
// $old-label-gray:#6C7693;
$old-label-gray: rgb(108, 118, 147);
// $errorRed: #e45e5e;
$errorRed: rgb(228, 94, 94);
// $white: #ffffff;
$white: rgb(255, 255, 255);
// $black: #000000;
$black: rgb(0, 0, 0);
// $toastMessageError: #fc642d
$toastMessageError: rgb(252, 100, 45);
// $toastMessageInfo #58A8F7
$toastMessageInfo: rgb(88, 168, 247);
// $toastMessageWarning f5bf00
$toastMessageWarning: rgb(245, 191, 0);
// $newBrandGreen: #7dd696;
$newBrandGreen: rgb(125, 214, 150);
// $newBrandBlue:#5997E9;
$newBrandBlue: rgb(89, 151, 233);
// $newBrandYellow: #f7bf21;
$newBrandYellow: rgb(247, 191, 33);
// $newBrandYellowHover: #dda508;
$newBrandYellowHover: rgb(221, 165, 8);

$gray-medium-dark: #dddddd;
$gray-medium: #bbbbbb;
$gray-100: #F9F9FB;
$gray-200: #E2E4E9;
$gray-600: #545D78;
$gray-700: #434B65;
$gray-800: #2D3243;
$orange: #ffbd6b;
$orange-yellow: #ffc106;
$red-alert: #fe5d5d;
$red-message: #dc3545;
$red-dark: #B71417;
$blue-medium: #61a1dd;
$dark-blue: #5a777b;
$medium-bg-grey: #B4BACB;
$light-blue-color: #5A92FF;
$errorRed: $orange-yellow;
$button-hover: #43AB4F;

$section-title-color: #1B2131;
$option-title-color: #2D3243;
$option-description-color: #434B65;

$placeholder-color: #6C7693;
$blue-color: #5299ed;

$darker-grey-color: #F0F1F5;
$medium-grey-color: #F9F9FB;
$light-grey-background-color: #FFFFFF;

// breakpoint variables
$minimum: 370px;
$xsmall: 480px;
$small: 576px;
$middle: 768px;
$large: 992px;
$xlarge: 1200px;
$xxlarge: 1500px;

// fonts
$default-font: "Poppins";
$font: $default-font, Helvetica, Arial, Lucida, sans-serif;

// sizes
$font-size-xs: 10.5px;
$font-size-sm: 12px;
$font-size-base: 16px;
$font-size-lg: 21px;
$font-size-xtra: 36px;

// font weight variables
$weight-light: 100;
$weight-200: 200;
$weight-300: 300;
$weight-medium: 300;
$weight-400: 400;
$weight-bold: 500;
$weight-600: 600;
$weight-bolder: 700;

// border radius
$border-radius-base: 6px;